import React from 'react';
import Form, { FormGroup, Label, Input, Checkbox } from 'components/ui/form';
import { Row, Col } from 'components/ui/wrapper';
import Button from 'components/ui/button';
import Anchor from 'components/ui/anchor';

const NewsletterForm = ({ inputStyle }) => {
  return (
    <Form
      action="https://atbventures.us4.list-manage.com/subscribe/post"
      method="POST"
    >
      <input type="hidden" name="u" value="ba96a1c6f9fa269660f4104b4" />
      <input type="hidden" name="id" value="ba1454213b" />
      <FormGroup mb="20px">
        <Input
          type="text"
          name="NAME"
          placeholder="Your Name *"
          required
          {...inputStyle}
        />
      </FormGroup>
      <FormGroup mb="20px">
        <Input
          type="email"
          name="EMAIL"
          placeholder="Email *"
          required
          {...inputStyle}
        />
      </FormGroup>
      <Row>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Label>
              <Checkbox name="group[67388][1]" required />I agree to receiving
              ATB Ventures&apos; newsletter. Please refer to the&nbsp;
              <Anchor
                color="primary"
                path={'/privacy-policy'}
                target={'_blank'}
              >
                Privacy Policy
              </Anchor>
              &nbsp;for more details.
            </Label>
          </FormGroup>
        </Col>
        <Col lg={12}>
          <Button type="submit" name="subscribe" hover="1">
            Sign up
          </Button>
        </Col>
      </Row>

      {/* Anti-spam fields for MailChimp */}
      <div
        style={{ position: 'absolute', left: '-5000px' }}
        aria-hidden="true"
        aria-label="Please leave the following three fields empty"
      >
        <label htmlFor="b_name">Name: </label>
        <input
          type="text"
          name="b_name"
          tabIndex="-1"
          value=""
          placeholder="Freddie"
          id="b_name"
          onChange={undefined}
        />

        <label htmlFor="b_email">Email: </label>
        <input
          type="email"
          name="b_email"
          tabIndex="-1"
          value=""
          placeholder="emailhere"
          id="b_email"
          onChange={undefined}
        />

        <label htmlFor="b_comment">Comment: </label>
        <textarea
          name="b_comment"
          tabIndex="-1"
          placeholder="Please comment"
          id="b_comment"
        ></textarea>
      </div>
    </Form>
  );
};

NewsletterForm.defaultProps = {
  inputStyle: {
    bgcolor: 'white',
  },
};

export default NewsletterForm;
