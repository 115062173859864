import React from 'react';
import PropTypes from 'prop-types';
import { LinkWrapper, AnchorTag } from './anchor.style';
import { FC } from 'react';

export interface AnchorProps extends AnchorStyle {
  path: string;
  children: React.ReactChild | string;
  target?: string;
}

export interface AnchorStyle {
  color?: string;
  fontSize?: string;
  align?: string;
  lineheight?: string | number;
  pt?: string;
  pb?: string;
  mt?: string;
  mb?: string;
  maxWidth?: string | number;
  fontWeight?: string | number;
  hoverstyle?: string;
}

const Anchor: FC<AnchorProps> = ({ path, children, ...props }) => {
  const internal = /^\/(?!\/)/.test(path);
  if (internal) {
    return (
      <LinkWrapper to={path} {...props}>
        {children}
      </LinkWrapper>
    );
  }
  return (
    <AnchorTag href={path} {...props}>
      {children}
    </AnchorTag>
  );
};

Anchor.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  align: PropTypes.string,
  lineheight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.string,
  pb: PropTypes.string,
  mt: PropTypes.string,
  mb: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Anchor;
