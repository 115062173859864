import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialInstagram,
  TiSocialLinkedin,
} from 'react-icons/ti';
import parse from 'html-react-parser';
import { Container } from 'react-bootstrap';
import Logo from 'assets/images/logo/atb-ventures-logo.png';
import { Row, Col } from 'components/ui/wrapper';
import Text, { TextStyle } from 'components/ui/text';
import Anchor, { AnchorStyle } from 'components/ui/anchor';
import Heading from 'components/ui/heading';
import Social, { SocialLink } from 'components/ui/social';
import NewsletterForm from 'components/newsletter-form';
import {
  FooterWrap,
  FooterTop,
  FooterWidget,
  LogoWidget,
  TextWidget,
  FooterWidgetList,
  FooterBottom,
} from './footer.style';
import { FC } from 'react';

interface FooterProps {
  reveal?: 'true' | 'false';
  copyrightStyle?: TextStyle;
  anchorStyle?: AnchorStyle;
}

const Footer: FC<FooterProps> = ({ copyrightStyle, anchorStyle, ...props }) => {
  const siteInfo = useStaticQuery(graphql`
    query FooterSiteQuery {
      site {
        siteMetadata {
          copyright
          contact {
            email
            address
          }
          social {
            twitter
            linkedin
          }
        }
      }
      allMenuJson {
        edges {
          node {
            id
            text
            link
          }
        }
      }
    }
  `);
  const { phone, address } = siteInfo.site.siteMetadata.contact;
  const { copyright } = siteInfo.site.siteMetadata;
  const {
    facebook,
    twitter,
    instagram,
    linkedin,
  } = siteInfo.site.siteMetadata.social;

  return (
    <FooterWrap {...props}>
      <FooterTop>
        <Container>
          <Row>
            <Col lg={4} sm={6}>
              <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                <LogoWidget>
                  <img src={Logo} alt="Logo" />
                </LogoWidget>
                <TextWidget>
                  {address && (
                    <Text mb="10px" fontSize="14px">
                      {address}
                    </Text>
                  )}
                  {phone && (
                    <Text mb="10px" fontSize="14px">
                      <Anchor
                        path={`tel:${phone}`}
                        fontWeight="800"
                        color="#333"
                        hoverstyle="2"
                      >
                        {phone}
                      </Anchor>
                    </Text>
                  )}
                </TextWidget>
              </FooterWidget>
            </Col>
            <Col lg={2} md={4} sm={6}>
              <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                <Heading as="h6" mt="-3px" mb="20px">
                  <Anchor path="/" color="textColor" hoverstyle="2">
                    Home
                  </Anchor>
                </Heading>
                <FooterWidgetList>
                  <li>
                    <Anchor path="/about-us" {...anchorStyle}>
                      About Us
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/portfolio" {...anchorStyle}>
                      Portfolio
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/partnerships" {...anchorStyle}>
                      Partnerships
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/venture-capital" {...anchorStyle}>
                      Venture Capital
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/responsible-ai" {...anchorStyle}>
                      Responsible AI
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/news" {...anchorStyle}>
                      News &amp; Stories
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
            <Col lg={6} md={8} sm={12}>
              <FooterWidget>
                <Heading as="h6" mt="-3px" mb="20px">
                  Sign up for the newsletter
                </Heading>
                <NewsletterForm />
              </FooterWidget>
            </Col>
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-center text-md-left">
              {copyright && (
                <Text {...copyrightStyle}>
                  &copy; {new Date().getFullYear()} {parse(copyright)}
                </Text>
              )}
            </Col>
            <Col md={6} className="text-center text-md-right">
              <Social space={8} shape="rounded" varient="outlined">
                {instagram && (
                  <SocialLink path={instagram} label="Instagram">
                    <TiSocialInstagram />
                  </SocialLink>
                )}
                {twitter && (
                  <SocialLink path={twitter} label="Twitter">
                    <TiSocialTwitter />
                  </SocialLink>
                )}
                {facebook && (
                  <SocialLink path={facebook} label="Facebook">
                    <TiSocialFacebook />
                  </SocialLink>
                )}
                {linkedin && (
                  <SocialLink path={linkedin} label="LinkedIn">
                    <TiSocialLinkedin />
                  </SocialLink>
                )}
              </Social>
            </Col>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrap>
  );
};

Footer.propTypes = {
  anchorStyle: PropTypes.object,
};

Footer.defaultProps = {
  reveal: 'false',
  copyrightStyle: {
    responsive: {
      small: {
        pb: '15px',
      },
    },
  },
  anchorStyle: {
    color: 'textColor',
    hoverstyle: '2',
    fontSize: '14px',
  },
};

export default Footer;
