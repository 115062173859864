import styled, { css } from 'styled-components';
import { device, StyledComponentsBaseStylingProps, ResponsiveStyles } from 'theme';

export const TextWrapper = styled.div< StyledComponentsBaseStylingProps & { color?: 'primary' | 'secondary' | 'headingColor' | string } & { responsive?: ResponsiveStyles }>`
  ${(props) =>
    props.color === 'primary' &&
    css`
      color: ${(props) => props.theme.colors.themeColor};
    `}
  ${(props) =>
    props.color === 'secondary' &&
    css`
      color: ${(props) => props.theme.colors.secondary};
    `}
    ${(props) =>
    props.color === 'headingColor' &&
    css`
      color: ${(props) => props.theme.colors.headingColor};
    `}
    ${(props) =>
    (props.color !== 'primary') &&
    (props.color !== 'secondary') &&
    (props.color !== 'headingColor') &&
    css`
      color: ${props.color || props.theme.colors.textColor};
    `};
  ul {
    list-style: disc inside;
  }
  a {
    display: inline;
  }
  font-size: ${(props) => props.fontSize};
  text-align: ${(props) => props.align};
  line-height: ${(props) => props.lineHeight};
  max-width: ${(props) => props.maxwidth};
  font-weight: ${(props) => props.fontWeight};
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
      ${(props) =>
    props.responsive?.xlarge &&
    css`
          @media ${device.xlarge} {
            font-size: ${props.responsive.xlarge.fontSize};
            text-align: ${props.responsive.xlarge.align};
            line-height: ${props.responsive.xlarge.lineHeight};
            max-width: ${props.responsive.xlarge.maxwidth};
            font-weight: ${props.responsive.xlarge.fontWeight};
            padding-top: ${props.responsive.xlarge.pt};
            padding-bottom: ${props.responsive.xlarge.pb};
            margin: ${props.responsive.xlarge.m};
            margin-top: ${props.responsive.xlarge.mt};
            margin-bottom: ${props.responsive.xlarge.mb};
            margin-left: ${props.responsive.xlarge.ml};
            margin-right: ${props.responsive.xlarge.mr};
          }
        `}
      ${(props) =>
    props.responsive?.large &&
    css`
          @media ${device.large} {
            font-size: ${props.responsive.large.fontSize};
            text-align: ${props.responsive.large.align};
            line-height: ${props.responsive.large.lineHeight};
            max-width: ${props.responsive.large.maxwidth};
            font-weight: ${props.responsive.large.fontWeight};
            padding-top: ${props.responsive.large.pt};
            padding-bottom: ${props.responsive.large.pb};
            margin: ${props.responsive.large.m};
            margin-top: ${props.responsive.large.mt};
            margin-bottom: ${props.responsive.large.mb};
            margin-left: ${props.responsive.large.ml};
            margin-right: ${props.responsive.large.mr};
          }
        `}
        ${(props) =>
    props.responsive?.medium &&
    css`
          @media ${device.medium} {
            font-size: ${props.responsive.medium.fontSize};
            text-align: ${props.responsive.medium.align};
            line-height: ${props.responsive.medium.lineHeight};
            max-width: ${props.responsive.medium.maxwidth};
            font-weight: ${props.responsive.medium.fontWeight};
            padding-top: ${props.responsive.medium.pt};
            padding-bottom: ${props.responsive.medium.pb};
            margin: ${props.responsive.medium.m};
            margin-top: ${props.responsive.medium.mt};
            margin-bottom: ${props.responsive.medium.mb};
            margin-left: ${props.responsive.medium.ml};
            margin-right: ${props.responsive.medium.mr};
          }
        `}
        ${(props) =>
    props.responsive?.small &&
    css`
          @media ${device.small} {
            font-size: ${props.responsive.small.fontSize};
            text-align: ${props.responsive.small.align};
            line-height: ${props.responsive.small.lineHeight};
            max-width: ${props.responsive.small.maxwidth};
            font-weight: ${props.responsive.small.fontWeight};
            padding-top: ${props.responsive.small.pt};
            padding-bottom: ${props.responsive.small.pb};
            margin: ${props.responsive.small.m};
            margin-top: ${props.responsive.small.mt};
            margin-bottom: ${props.responsive.small.mb};
            margin-left: ${props.responsive.small.ml};
            margin-right: ${props.responsive.small.mr};
          }
        `}
        ${(props) =>
    props.responsive?.xsmall &&
    css`
          @media ${device.xsmall} {
            font-size: ${props.responsive.xsmall.fontSize};
            text-align: ${props.responsive.xsmall.align};
            line-height: ${props.responsive.xsmall.lineHeight};
            max-width: ${props.responsive.xsmall.maxwidth};
            font-weight: ${props.responsive.xsmall.fontWeight};
            padding-top: ${props.responsive.xsmall.pt};
            padding-bottom: ${props.responsive.xsmall.pb};
            margin: ${props.responsive.xsmall.m};
            margin-top: ${props.responsive.xsmall.mt};
            margin-bottom: ${props.responsive.xsmall.mb};
            margin-left: ${props.responsive.xsmall.ml};
            margin-right: ${props.responsive.xsmall.mr};
          }
        `}
`;
