import React, { Component } from 'react';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
} from './checkbox.style';

interface CheckboxProps {
  name: string;
  id?: string;
  className?: string;
  required?: boolean;
}

export class Checkbox extends Component<CheckboxProps> {

  constructor(props: any) {
    super(props);
    this.state = { checked: false };
  }

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ checked: event.target?.checked });

  render () {
    return (
      <CheckboxContainer className={this.props.className}>
        <HiddenCheckbox
          checked={this.state.checked}
          onChange={this.handleCheckboxChange}
          name={this.props.name}
          id={this.props.id}
          required={this.props.required}
        />
        <StyledCheckbox checked={this.state.checked}>
          <Icon viewBox="0 0 24 36">
            <polyline points="20 0 10 12 4 8" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
    );
  }
}
