import styled, { css } from 'styled-components';
import { device, ResponsiveStyles, StyledComponentsBaseStylingProps } from 'theme';

export const FooterWrap = styled.footer<{ reveal?: 'true' | 'false' }>`
  background-color: #f9f9f9;
  ${(props) =>
    props.reveal === 'true' &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    `};
`;

export const FooterTop = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  @media ${device.medium} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media ${device.small} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const FooterBottom = styled.div`
  padding-bottom: 30px;
`;

export const FooterWidget = styled.div<{ responsive?: ResponsiveStyles } & StyledComponentsBaseStylingProps >`
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
      ${(props) =>
    props.responsive?.large &&
    css`
          @media ${device.large} {
            margin-top: ${props.responsive.large.mt};
            margin-bottom: ${props.responsive.large.mb};
            margin-left: ${props.responsive.large.ml};
            margin-right: ${props.responsive.large.mr};
          }
        `}
      ${(props) =>
    props.responsive?.medium &&
    css`
          @media ${device.medium} {
            margin-top: ${props.responsive.medium.mt};
            margin-bottom: ${props.responsive.medium.mb};
            margin-left: ${props.responsive.medium.ml};
            margin-right: ${props.responsive.medium.mr};
          }
        `}
      ${(props) =>
    props.responsive?.small &&
    css`
          @media ${device.small} {
            margin-top: ${props.responsive.small.mt};
            margin-bottom: ${props.responsive.small.mb};
            margin-left: ${props.responsive.small.ml};
            margin-right: ${props.responsive.small.mr};
          }
        `}
      ${(props) =>
    props.responsive?.xsmall &&
    css`
          @media ${device.xsmall} {
            margin-top: ${props.responsive.xsmall.mt};
            margin-bottom: ${props.responsive.xsmall.mb};
            margin-left: ${props.responsive.xsmall.ml};
            margin-right: ${props.responsive.xsmall.mr};
          }
        `}
`;

export const LogoWidget = styled.figure`
  max-width: 60%;
  margin-bottom: 30px;
`;

export const TextWidget = styled.div``;

export const FooterWidgetList = styled.ul`
  li {
    &:not(:last-child) {
      margin-bottom: 0px;
    }
  }
`;
