import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: text-top;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${(props) => props.theme.colors.themeColor};
  stroke-width: 4px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 6px;
  border: solid 1px #ccc;
  background: white;
  ${HiddenCheckbox}:focus + & {
    border: solid 1px ${(props) => props.theme.colors.themeColor};
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
