import React from 'react';
import { FormWrap } from './form.style';
import { FormGroup } from './form-group';
import { Error } from './error';
import { Label } from './label.style';
import { Input } from './input.style';
import { Checkbox } from './checkbox';
import { Select } from './select.style';
import { Textarea } from './textarea.style';

const Form = ({ children, ...props }) => {
  return <FormWrap {...props}>{children}</FormWrap>;
};

export { FormGroup, Label, Input, Checkbox, Select, Textarea, Error };

export default Form;
