import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { TextWrapper } from './text.style';
import { ResponsiveStyles } from 'theme';

export interface TextProps extends TextStyle {
  children: ReactNode;
}

export interface TextStyle {
  color?: string;
  fontSize?: string;
  align?: string;
  lineHeight?: string | number;
  pt?: string;
  pb?: string;
  mt?: string;
  mb?: string;
  maxWidth?: string | number;
  fontWeight?: string | number;
  as?:
    | 'p'
    | 'a'
    | 'span'
    | 'strong'
    | 'em'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6';
  responsive?: ResponsiveStyles;
}

const Text: FC<TextProps> = ({ children, ...props }) => {
  return <TextWrapper {...props}>{children}</TextWrapper>;
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  align: PropTypes.string,
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.string,
  pb: PropTypes.string,
  mt: PropTypes.string,
  mb: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  as: PropTypes.oneOf([
    'p',
    'a',
    'span',
    'strong',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
  ]),
  responsive: PropTypes.object,
};

export default Text;
